// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate} from 'react-router-dom'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,    
    Stack,
    DialogContentText,
    IconButton,
    Collapse,
    Alert,
    Button
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { DataGrid } from '@mui/x-data-grid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Refresh as RefreshIcon,    
    Edit as EditIcon,    
    Delete as DeleteIcon,
    Add as AddIcon,        
    Close as CloseIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {isAdministrator, API_URL_PHONEZOLUTIONS, API_URL_SIMPLECRM} from '../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,    
    SimpleUIDialogConfirm,
} from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Dialogg from './components/dialog'
import FormRun from '../workers/edit/run'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const params = useParams()
    const {user, accessToken} = SimpleUIAuthState()    
    const navigate = useNavigate()

    const formStateDefault = {
        disabled: false,                                      
        error: false,    
        errorText: "",        
    }

    const [formState, setFormState] = useState(formStateDefault)
    const [toolbarState, setToolbarState] = useState({ create: true, delete: false, edit: false, refresh: true })
    
    const [dialogStateDelete, setDialogDelete] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
          
    const [type, setType] = useState({})

    const [dialogAction, setDialogAction] = useState({})

    const [dataGridColumns, setDataGridColumns] = useState([])
    const [dataGridRows, setDataGridRows] = useState([])
    const [dataGridSelectionModel, setDataGridSelectionModel] = useState([]) 
        
    useEffect(() => {
        handleRefresh ()            
    }, [])
       
    const handleCreate = () => {
        // if ((((type || {}).layouts || {}).navigation || {}).editRoute) 
            // navigate(type.layouts.navigation.editRoute.replace(":entityId", "00000000-0000-0000-0000-000000000000"))

        // console.log (type)
        if (type?.navigation?.url) {
            // console.log (type.navigation.url+"/".replace(":entityId", "00000000-0000-0000-0000-000000000000"))
            navigate(`${type.navigation.url}/00000000-0000-0000-0000-000000000000`)
            // navigate(type.navigation.url+"/".replace(":entityId", "00000000-0000-0000-0000-000000000000"))
        } else {
            navigate('/entities/'+ "00000000-0000-0000-0000-000000000000") 
        }
    }

    const handleEdit = () => {
        if ((((type || {}).layouts || {}).navigation || {}).editRoute) 
            navigate(type.layouts.navigation.editRoute.replace(":entityId", dataGridSelectionModel[0]))
        else
            navigate('/entities/'+ dataGridSelectionModel[0])        
    }         

    const handleDelete = () => {
        setDialogDelete({
            open: true,
            title: "Delete entity",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this {type.name || "entity"}?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
                try {
                    let entityDelete = await fetch(API_URL_SIMPLECRM.ENTITIES + dataGridSelectionModel, {
                        method: 'DELETE', 
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer "+ accessToken
                        }
                    })  
        
                    if (!entityDelete.ok)
                        throw new Error((await entityDelete.json()).error.code)
        
                    handleRefresh()
                } catch (error) {
                    handleError(error)
                }                
            },
            onDecline: () => {}
        })
    }

    const handleRefresh = async () => {
        try {        
            let columns = [
                { field: 'id', headerName: 'Id', flex: 1.0 },        
                { field: 'typeId', headerName: 'Type', flex: 0.5,
                    renderCell: (params) => {                
                        // try {return types.find(o => o.id === params.value).name} catch (error) {return "Unknown"}
                    }
                }              
            ]
            
            const typeId = (props.typeId || params.typeId)

            if (typeId) {                
                let fetchGet1 = await fetch(API_URL_SIMPLECRM.TYPES + typeId)

                if (!fetchGet1.ok)
                    throw new Error((await fetchGet1.json()).error.code)

                let type = await fetchGet1.json()
                setType(type)
                
                if (type?.layoutsD?.list) {
                    // const layout = type.layouts.find((layout) => layout.type === "list")

                    columns = []
                    for (const column of type?.layoutsD?.list.content.columns) {
                        // console.log (column) 
                        columns.push({
                            headerName: column.headerName || type.fields[column.fieldId].label,
                            field: column.fieldId,
                            flex: column.flex,
                            
                        })
                    }
                    // console.log (columns)
                }

                let fetchGet2 = await fetch(`${API_URL_SIMPLECRM.ENTITIES}?typeId=${typeId}`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })
                
                if (!fetchGet2.ok)
                    throw new Error((await fetchGet2.json()).error.code)

                setDataGridColumns(columns)
                setDataGridRows(await fetchGet2.json())
            } else {
                let getTypes = await fetch(API_URL_SIMPLECRM.TYPES, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           
    
                if (!getTypes.ok)
                    throw new Error((await getTypes.json()).error.code)
    
                let types = await getTypes.json()

                let getCustomers = await fetch(API_URL_SIMPLECRM.ENTITIES, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })      

                if (!getCustomers.ok)
                    throw new Error((await getCustomers.json()).error.code)

                // setDataGridColumns(
                // [
                //     { field: 'id', headerName: 'Id', flex: 1.0 },        
                //     { field: 'typeId', headerName: 'Type', flex: 0.5,
                //         renderCell: (params) => {                
                //             try {return types.find(o => o.id === params.value).name} catch (error) {return "Unknown"}
                //         }
                //     }              
                // ])
                setDataGridColumns(columns)
                setDataGridRows(await getCustomers.json())
            }            
        } catch (error) {            
            console.log (error)
            // handleError(error)
        }        
    }
        
    const handleDataGridOnRowSelectionModelChange = (selection) => {
        setDataGridSelectionModel(selection)            
        if (selection.length != 0)
            setToolbarState({ ...toolbarState, delete: true, edit: true })
        else
            setToolbarState({ ...toolbarState, delete: false, edit: false })
    }

    const handleDataGridClickAway = () => {}

    const handleError = (error) => {
        console.log (error)

        setFormState({ ...formState, error: true, errorText: "Der opstod en uventet fejl." })
    }

    const handleButtonAction = (action) => {
        console.log (action)

        setDialogAction({
            open: true,            
            children: (<FormRun dialogState={dialogAction} setDialogState={setDialogAction} workerId={action.payload.workerId}></FormRun>)
        })
    }

    return (
        <React.Fragment>
            <Dialogg fullWidth={true} maxWidth={'md'} dialogState={dialogAction} setDialogState={setDialogAction}></Dialogg>
            <SimpleUIDialogConfirm dialogState={dialogStateDelete} setDialogState={setDialogDelete}/>
            <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100%)',
                        width: '100%'
                    }}
                >
                <Collapse in={formState.error}>
                    <Alert variant="filled" severity="error" 
                        action={
                            <IconButton aria-label="close" color="inherit" size="small"
                                onClick={() => {
                                    setFormState({ ...formState, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formState.errorText}
                    </Alert>
                </Collapse>             
                <Stack spacing={2} direction="row" justifyContent="end" sx={{pb: '20px'}}>
                    {!params.typeId 
                        ?
                        <React.Fragment>
                            {type?.layoutsD?.list?.content?.buttons?.map(function(button) {                        
                                return (
                                    <Button variant="contained" onClick={(event) => {handleButtonAction(button.action)}} >{button.title}</Button>
                                )
                            })}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Button variant="contained" onClick={() => {navigate(-1)}}>Back</Button>
                        </React.Fragment>
                    }

                    <IconButton color="primary" aria-label="create" onClick={handleCreate} disabled={!toolbarState.create}>
                        <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="edit" onClick={handleEdit} disabled={!toolbarState.edit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="delete" onClick={handleDelete} disabled={!toolbarState.delete}>
                        <DeleteIcon />
                    </IconButton>                                    
                    <IconButton aria-label="refresh" onClick={handleRefresh} disabled={!toolbarState.refresh}>
                        <RefreshIcon />
                    </IconButton>
                </Stack>
                <DataGrid              
                    disableColumnMenu
                    columns={dataGridColumns} 
                    rows={dataGridRows}
                    onRowSelectionModelChange={handleDataGridOnRowSelectionModelChange}
                    rowsPerPageOptions={[100]} 
                    selectionModel={dataGridSelectionModel}                    
                />                
            </Paper>    
        </React.Fragment>    
    )
}