// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Stack,
    Button,
    IconButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Edit as EditIcon,
    Delete as RemoveIcon,
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { DataGrid } from '@mui/x-data-grid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Render from './../../render'
import Element from './../../element'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const {layout, formData, handleAdd, handleEdit, handleDelete} = props

    const [dataGridColumns, setDataGridColumns] = useState([])
    const [dataGridRows, setDataGridRows] = useState([])
    const [dataGridSelectionModel, setDataGridSelectionModel] = useState([]) 

    const [expanded, setExpanded] = React.useState('panel1');    
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    const [renders, setRenders] = useState([])

    useEffect(() => {
        if (formData == undefined)
            return

        // let columns = []
        // for (const column of layout.content.component.props.columns) {
        //     switch (column.type) {
        //         case "data": {
        //             columns.push({
        //                 headerName: column.headerName || "",
        //                 field: column.field,
        //                 flex: column.flex,
        //                 renderCell: (params) => {
        //                     return (params.row[column.field])
        //                 }
        //             })
        //             break
        //         }
                
        //         case "buttons": {
        //             columns.push({
        //                 headerName: "",                        
        //                 flex: 0.3,
        //                 sortable: false,
        //                 renderCell: (params) => {
        //                     const onClickEdit = () => {
        //                         handleEdit(params.row.id)
        //                     }

        //                     const onClickDelete = () => {
        //                         handleDelete(params.row.id)
        //                     }

        //                     return (
        //                         <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                   
        //                             <IconButton aria-label="delete" size="small" onClick={onClickEdit}>
        //                                 <EditIcon fontSize="inherit"/>
        //                             </IconButton>
                                        
        //                             <IconButton aria-label="delete" size="small" onClick={onClickDelete}>
        //                                 <RemoveIcon fontSize="inherit"/>
        //                             </IconButton>
        //                         </Stack>
        //                     )
        //                 }
        //             })
        //             break
        //         }
        //     }           
        // }

        // let rows = []
        // for (const entity of props.formData) {
        //     if (entity.data)
        //         rows.push({id: entity.id, typeId: entity.typeId, ...entity.data})
        //     else
        //         rows.push(entity)
        // }

        // setDataGridColumns(columns)
        // setDataGridRows(rows)

    }, [formData])

    useEffect(() => {
        // if (formData === null)
        //     return

        // const valid = ((formData.parameters.find(parameter => (parameter.type == "entity" && !parameters[parameter.id].valid))) ? false : true)
        
        // setFormState({...formState,
        //     valid: valid,
        //     mainButtonDisabled: !valid,
        // })

    },[renders])


    const handleOnChangeRender = (id, renderFormState, typeId, entityData, callback) => {
        // console.log (renderFormState)
        // setParameters({...parameters, [parameterId]: {valid: renderFormState.valid, value: "", callback: callback}})

        setRenders(renders => [...renders, {id: id, valid: renderFormState.valid, callback: callback}]);

        // setRenders({...renders, []})

        // setParameters({...parameters, [parameterId]: {valid: renderFormState.valid, value: "", callback: callback}})
    }    
    
    // function getRowId(row) {
    //     return row.id
    // }

    // const handleDataGridOnRowSelectionModelChange = (selection) => {
    //     setDataGridSelectionModel(selection)            
    // }

    // console.log (formData)

    return (
        <React.Fragment>
            {formData.map(function (entity, index)  {                
                return (
                    <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls={`${entity.id}-content`}
                            id={entity.id}
                        >
                            {layout.build.summary.content.map((element, layoutIndex) => (
                                <Element
                                    element={element}
                                    parentType={undefined}

                                    entity={entity}
                                />
                            ))}                            
                        </AccordionSummary>
                        <AccordionDetails>
                            <Render 
                                entity={entity}                                
                                
                                onChange={
                                    (renderFormState, typeId, entityData, callback) => {handleOnChangeRender(entity.id, renderFormState, typeId, entityData, callback)}
                                }
                            />
                        </AccordionDetails>
                  </Accordion>
                )
            })}  
        </React.Fragment>
    )
}