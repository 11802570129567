import * as React from 'react'

import {
    Box,
    CircularProgress,
} from '@mui/material/'

const Initializing = (props) => {    
    return (
        <Box style={{ height: (props.height || "100%"), width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
            <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
        </Box>
    )
}

export {
    Initializing    
}