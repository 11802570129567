// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Grid
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Refresh as RefreshIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    Close as CloseIcon,
    Save as SaveIcon,
    Visibility as ShowIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import RenderField from './renderField'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

const Element = (props) => {    
    const{element, parentType, ...otherProps} = props    
    // console.log ("--- E")
    // console.log (element)
    // console.log ("-----")

    switch (element.type) {
        case "grid": {
            return (
                <Grid {...element.props}>
                    {element.content.map((element) => (
                        <Element 
                            element={element}
                            parentType={"grid"}
                            {...otherProps}
                        />
                    ))}
                </Grid>
            )
        }

        case "typography": {
            return (
                <Typography {...element.props}>
                    {element.content.map((element) => (
                        <Element 
                            element={element}
                            parentType={"typography"}
                            {...otherProps}                            
                        />
                    ))}
                </Typography>
            )
        }

        case "string": {
            return (
                element.value
            )
        }

        case "field": {
            if (parentType == "typography") {
                return (otherProps.entity.data[element.value])
            } else {
                return (
                    <RenderField
                        fieldId={element.value}
                        {...otherProps}                    
                    />
                )
            }
        }

        default: {
            return (
                <React.Fragment/>
            )
        }
    }
}

export default Element